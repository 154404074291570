import React from 'react'
import '../style/pages/NasePriestory.scss'
import { motion } from 'framer-motion'
import shape1 from '../img/shape1.svg'
import shape2 from '../img/shape2.svg'


const NasePriestory = () => {
    return (
        <motion.div initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}}>
        <main className='videoMain'>
            <div className='videoWrapper'>
                <div class="videoContainer">
                    <div className='videoHeading'>
                        <h2>Pozrite sa,<br /> ako to u nás vyzerá.</h2>
                    </div>
                    <div class="formContainer">
                        <section className='videoForm'>
                        <iframe id="ytplayer" type="text/html" width="600" height="320"
                        src="https://www.youtube.com/embed/XdIVXRehBH0"
                        frameborder="0" title='video'></iframe>
                        </section>
                    </div>
                <img src={shape1} alt='shape1' className='shape1'/>
                </div>
            </div>
            <img src={shape2} alt='shape2' className='shape2'/>
        </main>
        </motion.div>
    )
}

export default NasePriestory
