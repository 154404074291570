function openFunction(){
    document.getElementById('kontaktSide').style.width='50%';
    document.getElementById('kontaktForm').style.visibility='visible';
    document.getElementById('kontaktForm').style.right='0';
}

function closeFunction(){
    document.getElementById('kontaktSide').style.width='0%';
    document.getElementById('kontaktForm').style.visibility='hidden';
    document.getElementById('kontaktForm').style.right='-255px';
}

export {openFunction , closeFunction}