import React from 'react'
import '../style/pages/NasaPonuka.scss'
import arrow from '../img/Vector.png'
import vector3 from '../img/Vector 3.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faImages } from '@fortawesome/free-solid-svg-icons'
import { motion } from 'framer-motion'
import { Link } from 'react-router-dom'
import velkyStol from '../img/velkyStol.svg'
import malyStol from '../img/malyStol.svg'
import zasadacka from '../img/zasadačka.svg'
import { openFunction , closeFunction } from '../scripts/sideMenu'
import { openFunction2 } from '../scripts/sideMenuResponsive'

const NasaPonuka = () => {
    return (
        <motion.div initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}}>
        <main>
            <div className="heading-content">
                <h2>Zabezpečte si<br /> miesto alebo<br /> zasadačku. <img src={vector3}  alt='vector3' className='vector3'/></h2>
                <div className="border"></div>
                <p>Väčší stôl 60€/mesiac, menší stôl 50€/mesiac. 
Zasadačka ideálna na stretnutia so zákazníkmi, kolegami a na školenia 10€/hodina</p>
            </div>
            <section className="offers">
                <div className="offerWrapper">
                    <h1 className="offerHeading">Malý Stôl</h1>
                    <div className="offerContent">
                        <article>
                            <h2 className='articleHeading'>Kreativita</h2>
                            <p className='article'>Wi-fi pripojenie, kuchynka a zasadačka. Malý zamykací box na osobné veci.</p>
                            <h2 className='price'>50 €/mesiac</h2>
                            <div className="button button1" onClick={openFunction}>Rezervuj <img src={arrow} alt='arrow'/></div>
                            <div className="button button2" onClick={openFunction2}>Rezervuj <img src={arrow} alt='arrow'/></div>
                        </article>
                        <div className='aside'>
                            <img className='offerImage' src={malyStol} alt='maly stôl ' />
                            <div className='icon-wrapper'>
                                <Link to='/nase_priestory'>
                                    <FontAwesomeIcon icon={faImages}  className='icon' />
                                </Link>
                                <p>Pozrite si video</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="offerWrapper special">
                    <h1 className="offerHeading">Veľký Stôl.</h1>
                    <div className="offerContent">
                        <article>
                            <h2 className='articleHeading'>Spolupráca</h2>
                            <p className='article'>Wi-fi pripojenie, kuchynka a zasadačka. Malý zamykací box na osobné veci.</p>
                            <h2 className='price'>60 €/mesiac</h2>
                            <div className="button button1" onClick={openFunction}>Rezervuj <img src={arrow} alt='arrow'/></div>
                            <div className="button button2" onClick={openFunction2}>Rezervuj <img src={arrow} alt='arrow'/></div>
                        </article>
                        <div className='aside'>
                        <img className='offerImage' src={velkyStol} alt='veľký stôl ' />
                            <div className='icon-wrapper'>
                                <Link to='/nase_priestory'>
                                    <FontAwesomeIcon icon={faImages}  className='icon' />
                                </Link>
                                <p>Pozrite si video</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="offerWrapper">
                    <h1 className="offerHeading">Zasadačka.</h1>
                    <div className="offerContent">
                        <article>
                            <h2 className='articleHeading'>Tímový duch</h2>
                            <p className='article'>Nevoďte si zákazníkov či kolegov k sebe domov, rezervujte si luxusnú zasadačku. 10€/hodina.</p>
                            <h2 className='price'> Ak tu máš stôl, zasadačka je zadarmo.</h2>
                            <div className="button button1" onClick={openFunction}>Rezervuj <img src={arrow} alt='arrow'/></div>
                            <div className="button button2" onClick={openFunction2}>Rezervuj <img src={arrow} alt='arrow'/></div>
                        </article>
                        <div className='aside'>
                        <img className='offerImage' src={zasadacka} alt='zasadacka' />
                            <div className='icon-wrapper'>
                                <Link to='/nase_priestory'>
                                    <FontAwesomeIcon icon={faImages}  className='icon' />
                                </Link>
                                <p>Pozrite si video</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="kontaktForm" id="kontaktSide">
                    <div onClick={closeFunction} className="closeButton">&times;</div>
                <div className="kontaktFormContent" id="kontaktForm">
                    <h1>Ozveme sa ti alebo<br/>sa ozvi ty nám!</h1>
                    <form className="forms" action="https://formsubmit.co/Monumentaling@gmail.com" method="POST">
                    <input type="hidden" name="_captcha" value="false"></input>
                        <div className='form1'>
                            <label>Email:</label>
                            <input type='email' name='email'></input>
                        </div>
                        <div className='form2'>
                            <div className="cislo">
                                <label>Tel. číslo:</label>
                                <input type='text' name='cislo'></input>
                            </div>
                            <div className="select">
                                <label>Mám záujem o:</label>
                                <select name='sluzba'>
                                    <option className='noOption'></option>
                                    <option>veľký stôl so samostatnou dvojskrinkou</option>
                                    <option>malý stôl so samostatnou jednoskrinkou</option>
                                    <option>zasadačka</option>
                                </select>
                            </div>
                        </div>
                            <textarea></textarea>
                        <input className='buttonSend' type='submit'></input>
                    </form>
                </div>
            </section>
        </main>
        </motion.div>
    )
}

export default NasaPonuka
