import React from 'react'
import Helmet from 'react-helmet'
import { Link } from 'react-router-dom'
/*import logo from '../img/logo.jpg' */
import logo2 from '../img/Logo2.png'
/*import logo2 from '../img/logo2.png'*/
import '../style/components/Header.scss'
import { openFunction2, closeFunction2 } from '../scripts/headerSide'

const Header = () => {
    return (
        <header>
            <nav>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Kopa</title>
                </Helmet>
                <div>
                    <Link to='/'><img src={logo2} alt='logo' /></Link>
                </div>
                <ul>
                    <li className='btn'><Link to='/'>domov</Link></li>
                    <li className='btn'><Link to='/nasa_ponuka'>naša ponuka</Link></li>
                    <li className='btn'><Link to='/nase_priestory'>naše priestory</Link></li>
                    <li className='btn'><Link to='/kontakt'>kontaktujte nás</Link></li>
                    <li className='hamburger' onClick={openFunction2}>&#9776;</li>
                </ul>
            </nav>
            <section className="headerSide" id="headerSide">
                    <div onClick={closeFunction2} className="closeButton">&times;</div>
                <div className="headerSideContent" id="headerForm">
                <ul>
                    <li className='btn' onClick={closeFunction2}><Link to='/'>domov</Link></li>
                    <li className='btn' onClick={closeFunction2}><Link to='/nasa_ponuka'>naša ponuka</Link></li>
                    <li className='btn' onClick={closeFunction2}><Link to='/nase_priestory'>naše priestory</Link></li>
                    <li className='btn' onClick={closeFunction2}><Link to='/kontakt'>kontaktujte nás</Link></li>
                </ul>
                </div>
            </section>
        </header>
    )
}

export default Header
