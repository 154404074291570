import React from 'react'
import { Link } from 'react-router-dom'
import '../style/pages/Home.scss'
/*import homeImage from '../img/almost1.png'*/
import homeImage2 from '../img/almost2.png'
import arrow from '../img/Vector.png'
import vector3 from '../img/Vector 3.png'
import { motion } from 'framer-motion'

const Home = () => {
    return (
    <motion.div initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}}>
        <main className='main'>
            <div className='wrapper'>
                <div class="content">
                    <h2>NOVÝ COWORK NA ORAVE</h2>
                    <p>Pracujete homeoffice?<br /> Hľadáte kanceláriu?</p>
                    <p className='p2'>V Oravskej Polhore voľné od<br /> 50€/mesiac<span className='collored-dot'>.</span></p>
                    <img src={vector3} alt='vector' className='vector3' />
                    <Link to='/Nasa_ponuka' className='button'>Pokračovať <img src={arrow} alt='sipka_smerom_vpravo' /></Link>
                </div>
                <div className='homeImageDiv'>
                    <img src={homeImage2} className='homeImage' alt='homeImage'/>
                    <p>Oravská Polhora / Babia Hora - 1 722,9 m.n.m </p>
                </div>
            </div>
        </main>
    </motion.div>
    )
}

export default Home
