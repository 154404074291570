function openFunction2(){
    document.getElementById('kontaktSide').style.width='100%';
    document.getElementById('kontaktForm').style.visibility='visible';
    document.getElementById('kontaktForm').style.right='0';
}

function closeFunction2(){
    document.getElementById('kontaktSide').style.width='0%';
    document.getElementById('kontaktForm').style.visibility='hidden';
    document.getElementById('kontaktForm').style.right='-255px';
}

export {openFunction2 , closeFunction2}