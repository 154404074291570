import { AnimatePresence } from 'framer-motion';
import { Route, Routes } from 'react-router';
import Layout from './components/Layout';
import Home from './pages/Home'
import Kontakt from './pages/Kontakt';
import NasaPonuka from './pages/NasaPonuka';
import NasePriestory from './pages/NasePriestory';

function App() {
  return (
    <AnimatePresence>
      <Layout>
        <Routes>
          <Route path='/' element={<Home />}></Route>
          <Route path='/nasa_ponuka' element={<NasaPonuka />}></Route>
          <Route path='/nase_priestory' element={<NasePriestory />}></Route>
          <Route path='/kontakt' element={<Kontakt />}></Route>
        </Routes>
      </Layout>
    </AnimatePresence>
  );
}

export default App;
