import React from 'react'
import '../style/pages/Kontakt.scss'
import shape1 from '../img/shape1.svg'
import shape2 from '../img/shape2.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faMapMarkerAlt, faPhoneAlt } from '@fortawesome/free-solid-svg-icons'
import { motion } from 'framer-motion'

const Kontakt = () => {
    return (
        <motion.div initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}}>
        <main className='contaktMain'>
            <div className='contaktWrapper'>
                <div className="contaktContainer">
                    <div className='contaktHeading'>
                        <h2>Vaše otázky môžete<br /> klásť tu, ozveme sa.</h2>
                    </div>
                    <div className="formContainer">
                        <section className='contaktForm'>
                            <form action="https://formsubmit.co/Monumentaling@gmail.com" method="POST">
                            <input type="hidden" name="_captcha" value="false"></input>
                                <div className='FirstLast'>
                                    <input type='text' name='meno' placeholder='Meno' required></input>
                                    <input type='text' name='priezvisko' placeholder='Priezvisko' required></input>
                                </div>
                                <input type='email' placeholder='Email' name='email' required></input>
                                <textarea name='sprava' placeholder='Napíš nám čo potrebuješ :)' required></textarea>
                                <input type='submit' className='button'></input>
                            </form>
                        </section>
                        <section className='contaktArticle'>
                            <div className="contaktArticleContent">
                                <h2>Kontaktné údaje</h2>
                                <ul>
                                    <li><FontAwesomeIcon icon={faPhoneAlt} /><p>+421 918 885 143</p></li>
                                    <li><FontAwesomeIcon icon={faEnvelope} /><p>Monumentaling@gmail.com</p></li>
                                    <li><FontAwesomeIcon icon={faMapMarkerAlt} /><p>Hasičská zbrojnica Oravská Polhora</p></li>
                                </ul>
                                <div class="mapouter"><div class="gmap_canvas"><iframe width="350" height="225" id="gmap_canvas" src="https://maps.google.com/maps?q=49.50943955775495,%2019.459563953080632&t=k&z=19&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe><a href="https://fmovies-online.net">fmovies</a><br /><a href="https://www.embedgooglemap.net">google maps on website</a></div></div>
                            </div>
                        </section>
                    </div>
                <img src={shape1} alt='shape1' className='shape1'/>
                </div>
            </div>
            <img src={shape2} alt='shape2' className='shape2'/>
        </main>
        </motion.div>
    )
}

export default Kontakt
