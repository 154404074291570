function openFunction2(){
    document.getElementById('headerSide').style.width='100%';
    document.getElementById('headerForm').style.visibility='visible';
    document.getElementById('headerForm').style.right='0';
}

function closeFunction2(){
    document.getElementById('headerSide').style.width='0%';
    document.getElementById('headerForm').style.visibility='hidden';
    document.getElementById('headerForm').style.right='-255px';
}

export {openFunction2 , closeFunction2}